<template> 
  <div class="split-products-shipment">
    <el-row class="split-products-shipment-row">
      <el-col :span="8">
        <span>SKU：</span>
        <span>{{ productData.sku }}</span>
      </el-col>
      <el-col :span="8">
        <span>中文报关名：</span>
        <span>{{ productData.productCn }}</span>
      </el-col>
      <el-col :span="8">
        <span>英文名：</span>
        <span>{{ productData.productEn }}</span>
      </el-col>
      <el-col :span="8">
        <span>规格：</span>
        <span>{{ productData.productSpecs || '/' }}</span>
      </el-col>
      <el-col :span="8">
        <span>总数量：</span>
        <span>{{ productData.totalNum }}</span>
      </el-col>
    </el-row>
    <el-form :model="productData"  ref="productForm" label-width="0" label-position="left" >
      <el-table
        ref="table"
        :data="productData.productSubList"
        align="center"
        border
        :header-cell-style="{ 'text-align': 'center' }"
        :cell-style="{ 'text-align': 'center' }"
      >
          <el-table-column prop="planShippingQuantity" label="计划出运数量">
            <template slot="header">
                  <p><span style="color: red;">*</span>计划出运数量</p>
                </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'productSubList.' +
                  scope.$index +
                  '.planShippingQuantity'
                "
                :rules="[
                      {
                        required: true,
                        message: $t('placeholder.plsInput'),
                        trigger: 'blur',
                      },
                      {
                        validator: (rule, value, callback) => {
                          if (!/^[1-9]\d*$/.test(value))  {
                            callback('请输入大于0的整数')
                          } else {
                            callback()
                          }
                        },
                        trigger: 'blur',
                      },
                    ]"
              >
                <el-input v-model.trim ="scope.row.planShippingQuantity" v-prevent-mousewheel type="number" :disabled="!!scope.row.shippedFlag"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="overLoadingQuantity" label="溢装数">
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'productSubList.' +
                  scope.$index +
                  '.overLoadingQuantity'
                "
                :rules="[                
                  {
                    validator: (rule, value, callback) => {
                      if(!value && value!== 0){
                        callback()
                      }else if (!/^(0|[1-9]\d*)$/.test(value))  {
                        callback('请输入整数')
                      }else{
                        callback()
                      } 
                    },
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model.trim ="scope.row.overLoadingQuantity" v-prevent-mousewheel type="number" :disabled="!!scope.row.shippedFlag"></el-input>
              </el-form-item>

            </template>
          </el-table-column>
          <el-table-column prop="caseNum" label="箱数">
            <template slot="header">
              <p><span style="color: red;">*</span>箱数</p>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'productSubList.' +
                  scope.$index +
                  '.caseNum'
                "
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (!/^[1-9]\d*$/.test(value))  {
                        callback('请输入大于0的整数')
                      } else {
                        callback()
                      }
                    },
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model.trim ="scope.row.caseNum" v-prevent-mousewheel type="number" :disabled="!!scope.row.shippedFlag"></el-input>
              </el-form-item>

            </template>
          </el-table-column>
          <el-table-column prop="grossWeight" label="重量">
            <template slot="header">
              <p><span style="color: red;">*</span>重量<span>（kg）</span></p>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'productSubList.' +
                  scope.$index +
                  '.grossWeight'
                "
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value <= 0)  {
                        callback('请输入大于0的整数')
                      } else {
                        callback()
                      }
                    },
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model.trim ="scope.row.grossWeight" v-prevent-mousewheel type="number" :disabled="!!scope.row.shippedFlag"
                oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column prop="volume" label="体积">
            <template slot="header">
              <p><span style="color: red;">*</span>体积<span>（m³）</span></p>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'productSubList.' +
                  scope.$index +
                  '.volume'
                "
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsInput'),
                    trigger: 'blur',
                  },
                  {
                    validator: (rule, value, callback) => {
                      if (value <= 0)  {
                        callback('请输入大于0的整数')
                      } else {
                        callback()
                      }
                    },
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input v-model.trim ="scope.row.volume" v-prevent-mousewheel type="number" :disabled="!!scope.row.shippedFlag"
                oninput="if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+3)}"></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column  width="160px">
            <template slot="header">
              <p><span style="color: red;">*</span>计划装运日期</p>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'productSubList.' +
                  scope.$index +
                  '.planShippingDate'
                "
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsSel'),
                    trigger: 'change',
                  },
                  {
                    validator: (rule, value, callback) => {
                      const customerDeliveryDate = new Date(scope.row.customerDeliveryDate);
                       if ( !isNaN(customerDeliveryDate.getTime()) &&new Date(value).getTime() > customerDeliveryDate.getTime()) {
                        callback('不能晚于客户交期');
                      } else {
                        callback(); 
                      }
                    },
                    trigger: 'change',
                  }
                ]"
              >
              <el-date-picker
                size="small"
                v-model="scope.row.planShippingDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="年/月/日"
                :disabled="!!scope.row.shippedFlag"
              ></el-date-picker>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column  width="160px">
            <template slot="header">
              <p><span style="color: red;">*</span>运输方式</p>
            </template>
            <template slot-scope="scope">
              <el-form-item
                :prop="
                  'productSubList.' +
                  scope.$index +
                  '.shippingMethodId'
                "
                :rules="[
                  {
                    required: true,
                    message: $t('placeholder.plsSel'),
                    trigger: 'change',
                  }
                ]"
              >
                <el-select
                  v-model="scope.row.shippingMethodId"
                  placeholder="请选择"
                  class="w100"
                  :disabled="!!scope.row.shippedFlag"
                  @change="changeShipMethod(scope.row)"
                >
                <el-option
                  v-for="item in shippingMethodList"
                  :key="item.id"
                  :label="item.transportWayCn"
                  :value="item.id"
                />
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <el-table-column label="客户交期">
            <template slot-scope="scope">
              <div>
                {{ scope.row.customerDeliveryDate }}
                <el-tag type="warning" size="mini"  effect="dark" v-if="!!scope.row.firmFlag">Firm 交期</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="60px">
            <template slot-scope="scope">
              <el-button type="text"   @click="deleteRowFn(scope.row,scope.$index)" v-if="!scope.row.shippedFlag">删除</el-button>
            </template>
          </el-table-column>
      </el-table>
      <div class="add-btn">
        <span @click="addRowFn">添加一行</span>
      </div>
      <el-form-item label="修改出运原因" prop="reason" label-width="120px" label-position="top"
      :rules="[
            {
              required: true,
              message: '请输入',
              trigger: 'blur',
            }
          ]"
      >
        <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 6 }" maxlength="500" show-word-limit
          v-model="productData.reason" ></el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'split-products-shipment',
  data() {
    return {  

    }
  },
  props: {
    editProductData: {
      type: Object,
      required: true,
    },
    shippingMethodList:{
      type: Array,
      default: () => [],
    }
  },
  computed: {
    productData: {
      get() {
        return this.editProductData
      },
      set(val) {
        this.$emit('update:editProductData',val)
      }
    },
  },
  methods: {
     //切换运输方式
     changeShipMethod(row) {
        this.shippingMethodList.forEach((item) => {
          if (row.shippingMethodId == item.id) {
            row.shippingMethodType = item.transportType
            row.shippingMethod = item.transportWayCn
          }
        })
      },
    // 添加一行
    addRowFn() {
      const lastRowData = this.productData.productSubList?.length ? this.productData.productSubList[this.productData.productSubList.length - 1] : {};
      this.productData.productSubList.push({
          caseNum:'',
          customerDeliveryDate:this.productData.customerDeliveryDate,
          firmFlag:this.productData.firmFlag,
          grossWeight:'',
          orderProductUniqueId:this.productData.orderProductUniqueId,
          overLoadingQuantity:'',
          planShippingDate:lastRowData?.planShippingDate ?? '',
          planShippingQuantity:'',
          shippingMethod:lastRowData?.shippingMethod ?? '',
          shippingMethodId:lastRowData?.shippingMethodId ?? '',
          shippingMethodType:lastRowData?.shippingMethodType ?? '',
          shippingPlanOrderId:this.productData.shippingPlanOrderId,
          shippingPlanOrderProductId:this.productData.shippingPlanOrderProductId,
          productSubId:'',
          sku:this.productData.sku,
          volume:'',
          shippedFlag:0,
      });
    },
    // 删除一行
    deleteRowFn(row,index) {
      if(this.productData?.productSubList?.length === 1){
        this.$message.warning('至少保留一行');
        return false;
      }
      this.$confirm(`是否确认删除?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',  
      }).then(() => {
        this.productData.productSubList.splice(index, 1);
        row.productSubId && this.productData.delProductSubIds.push(row.productSubId);
        }).catch((err) => {
          console.log(err)
        })
    },

    // 校验表格必填项
    checkTableForm() {
      return new Promise((resolve) => {
        this.$refs.productForm.validate((valid) => {
          if (valid) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },
  },
}
</script>
<style lang="scss" scoped>
.split-products-shipment{
  .split-products-shipment-row{
    margin: 0;
    .el-col{
      margin-bottom: 20px;
    }
  }
  .add-btn{
    padding: 10px;
    text-align: center;
    span{
      color: #1890FF;
      text-decoration: underline;
      cursor: pointer;
    }
  }
::v-deep {
  .el-form-item{
      margin: 14px 0;
      .el-date-editor{
        width: 100%;
      }
  }
}
}
</style>