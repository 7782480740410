<template>
  <div>
    <el-table
      ref="table"
      :data="tabledata"
      align="center"
      border
      :header-cell-style="{ 'text-align': 'center' }"
      :cell-style="{ 'text-align': 'center' }"
      @selection-change="tableSelect"
      @expand-change="changeTable"
      :span-method="(obj)=>arraySpanMethod(obj, tabledata)"
      :row-key="(row)=>getRowKey(row)"
      :expand-row-keys="expandedRowKeys"
    >
      <el-table-column type="selection" width="35">
        <template slot-scope="scoped">
          <div
           class="shipped-flag"
            v-show="
              scoped.row.mainFlag == 1 && scoped.row.splitFlag == 1
                ? false
                : true
            "
          >
            <el-checkbox
              v-model="scoped.row.checked"
              :disabled="!!scoped.row.shippedFlag"
              @change="changeParentSelect(scoped.row)"
            ></el-checkbox>
          </div>
        </template>
      </el-table-column>
      <el-table-column type="expand" width="35">
        <template
          slot-scope="sub"
          v-if="sub.row.mainFlag == 1 && sub.row.splitFlag == 1"
        >
          <el-table
            :data="sub.row.childProduct"
            :ref="sub.row.id"
            align="center"
            :show-header="sub.row.mainFlag == 1 && sub.row.splitFlag == 1"
            border
            :header-cell-style="{ 'text-align': 'center' }"
            :span-method="(obj)=>arraySpanMethod(obj, sub.row.childProduct)"
          >
            <el-table-column width="35" header-align="center"></el-table-column>
            <el-table-column width="35">
              <template slot-scope="scoped">
                <div class="shipped-flag">
                  <el-checkbox
                    v-model="scoped.row.checked"
                    @change="changeChildStatus(sub.row)"
                    :disabled="!!scoped.row.shippedFlag"
                  ></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="productImg" label="效果图" width="80">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  class="el-tag-other"
                  v-if="scope.row.sampleFlag"
                >
                  {{ $t('AddOrder.PrenatalSample') }}
                </el-tag>
                <div v-if="scope.row.productImg">
                  <erp-image :imgs="scope.row.productImg"></erp-image>
                  <!--mainFlag 是否主产品 0 否 1.是 -->
                  <popover-ship
                    :showTable="false"
                    :itemTitle="
                      scope.row.mainFlag === 1 && scope.row.splitFlag == 1
                        ? '主'
                        : scope.row.mainFlag == 0
                        ? '子'
                        : ''
                    "
                  ></popover-ship>
                </div>
                <span v-else>--</span>
              </template>
            </el-table-column>
            <el-table-column prop="sku" label="SKU ID" width="100">
              <template slot-scope="scope">
                <div class="page-link">
                  {{ scope.row.sku | emptyFilter }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="中文品名/英文品名"
              width="170px"
              show-overflow-tooltip
            >
              <template slot-scope="scope">
                <span>
                  {{ scope.row.productCn | emptyFilter }}/
                  {{ scope.row.productEn | emptyFilter }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="规格">
              <template slot-scope="scope">
                {{ scope.row.productSpecs || '/' }}
              </template>
            </el-table-column>
            <el-table-column label="产品材质">
              <template slot-scope="scope">
                {{ scope.row.productMaterial || '--' }}
              </template>
            </el-table-column>
            <el-table-column label="总数量">
              <template slot-scope="scope">
                <span>{{ addThousandSeparator(scope.row.totalNum) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="剩余待出运数量"
              width="80"
            >
            <template slot-scope="scope">
              <span>{{ addThousandSeparator(scope.row.unShippedNum) }}</span>
            </template>
           </el-table-column>
           <el-table-column
              label="计划出运数量"
              width="80"
            >
            <template slot-scope="scope">
              <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ addThousandSeparator(scope.row.planShippingQuantity) }}</span>
            </template>
            </el-table-column>
            <el-table-column
              label="溢装数量"
              width="80"
            >
            <template slot-scope="scope">
              <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.overLoadingQuantity || 0}}</span>
            </template>
            </el-table-column>
            <el-table-column
              label="状态"
            >
            <template slot-scope="scope">
              <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.shippedFlag ? '已出运' : '未出运' }}</span>
            </template>
            </el-table-column>
            <el-table-column label="计划装运日期" width="110">
              <template slot-scope="scope">
                <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.planShippingDate | emptyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column label="运输方式">
              <template slot-scope="scope">
                <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.shippingMethod | emptyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column label="客户交期" width="110">
              <template slot-scope="scope">
                <div>
                  <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.customerDeliveryDate | emptyFilter }}</span>
                  <el-tag type="warning" size="mini"  effect="dark" v-if="scope.row.firmFlag">Firm 交期</el-tag>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="箱数" width="80">
              <template slot-scope="scope">
                <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.caseNum | emptyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column width="95">
              <template slot="header">
                <p>重量（kg）</p>
              </template>
              <template slot-scope="scope">
                <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.grossWeight | emptyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column width="100">
              <template slot="header">
                <p>体积（m³）</p>
              </template>
              <template slot-scope="scope">
                <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.volume | emptyFilter }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="productOperation"
              width="80"
              label=" 产品运营"
            >
            <template slot-scope="scope">
                <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.productOperation}}</span>
            </template>
            </el-table-column>
            <el-table-column label="操作" width="60" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click="openDialog(scope.row,sub.row.childProduct)">修改</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column prop="productImg" label="效果图" width="80">
        <template slot-scope="scope">
          <el-tag size="mini" class="el-tag-other" v-if="scope.row.sampleFlag">
            {{ $t('AddOrder.PrenatalSample') }}
          </el-tag>
          <div v-if="scope.row.productImg">
            <erp-image :imgs="scope.row.productImg"></erp-image>
            <!--mainFlag 是否主产品 0 否 1.是 -->
            <popover-ship
              :showTable="false"
              :itemTitle="
                scope.row.mainFlag === 1 && scope.row.splitFlag == 1
                  ? '主'
                  : scope.row.mainFlag == 0
                  ? '子'
                  : ''
              "
            ></popover-ship>
          </div>
          <span v-else>--</span>
        </template>
      </el-table-column>
      <el-table-column prop="sku" label="SKU ID" width="100">
        <template slot-scope="scope">
          <div class="page-link" @click="showProDetail(scope.row)">
            {{ scope.row.sku | emptyFilter }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        prop="address"
        label="中文报关名/英文报关名"
        width="170px"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <span>
            {{ (scope.row.declareCn || scope.row.productCn) | emptyFilter }}/
            {{ (scope.row.declareEn || scope.row.productEn) | emptyFilter }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="productSpecs" label="规格">
        <template slot-scope="scope">
          <span>{{ scope.row.productSpecs | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="productMaterial" label="产品材质">
        <template slot-scope="scope">
          <span>{{ scope.row.productMaterial | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="总数量">
        <template slot-scope="scope">
          <span v-if="scope.row.mainFlag === 1 && scope.row.splitFlag == 1"> -- </span>
          <span v-else>{{ addThousandSeparator(scope.row.totalNum) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="unShippedNum"
        label="剩余待出运数量"
       width="80"
      >
      <template slot-scope="scope">
        <span v-if="scope.row.mainFlag === 1 && scope.row.splitFlag == 1"> -- </span>
        <span v-else>{{ addThousandSeparator(scope.row.unShippedNum) }}</span>
      </template>
      </el-table-column>
      <el-table-column
        label="计划出运数量"
        width="80"
      >
      <template slot-scope="scope">
        <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ addThousandSeparator(scope.row.planShippingQuantity) }}</span>
      </template>
      </el-table-column>
      <el-table-column
        label="溢装数量"
        width="80"
      >
      <template slot-scope="scope">
        <span v-if="scope.row.mainFlag === 1 && scope.row.splitFlag == 1"> -- </span>
        <span v-else :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.overLoadingQuantity || 0 }}</span>
      </template>
      </el-table-column>
      <el-table-column
        label="状态"
      >
      <template slot-scope="scope">
        <span v-if="scope.row.mainFlag === 1 && scope.row.splitFlag == 1"> -- </span>
        <span v-else :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.shippedFlag ? '已出运' : '未出运' }}</span>
      </template>
      </el-table-column>
      <el-table-column label="计划装运日期" width="110">
        <template slot-scope="scope">
          <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.planShippingDate | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="运输方式">
        <template slot-scope="scope">
         <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.shippingMethod | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column label="客户交期" width="110">
        <template slot-scope="scope">
          <div>
            <span :class="{'text-d7': !!scope.row.shippedFlag}"> {{ scope.row.customerDeliveryDate | emptyFilter }}</span>
            <el-tag type="warning" size="mini"  effect="dark" v-if="scope.row.firmFlag">Firm 交期</el-tag>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="箱数" width="80">
        <template slot-scope="scope">
         <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.caseNum | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column width="95">
        <template slot="header">
          <p>重量（kg）</p>
        </template>
        <template slot-scope="scope">
          <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.grossWeight | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column width="100">
        <template slot="header">
          <p>体积（m³）</p>
        </template>
        <template slot-scope="scope">
          <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.volume | emptyFilter }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="productOperation"
        width="80"
        label=" 产品运营"
      >
      <template slot-scope="scope">
          <span :class="{'text-d7': !!scope.row.shippedFlag}">{{ scope.row.productOperation}}</span>
      </template>
      </el-table-column>
      <el-table-column label="操作" width="60" align="center">
        <template slot-scope="scope">
          <el-button type="text" @click="openDialog(scope.row,tabledata)" v-if="!(scope.row.mainFlag == 1 && scope.row.splitFlag == 1)">修改</el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 操作弹窗 -->
    <el-dialog
      title="修改出运产品"
      :visible.sync="dialogVisible"
      width="1200px"
      top="100px"
      custom-class="query-dialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      >
      <span>
        <!-- 修改出运产品组件 -->
         <split-products-shipment v-if=" dialogVisible" ref="splitProductsShipmentRef"
         :editProductData="editProductData" :shippingMethodList="shippingMethodList"></split-products-shipment>
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary"  size="small" @click="confirmFn">
          确 定
        </el-button>
        <el-button @click="dialogVisible = false" size="small">
          取 消
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import popoverShip from './popoverShip.vue'
  import splitProductsShipment from './split-products-shipment.vue'
  import { ShippingPlanInteractor, OrderInteractor } from '@/core'
  import { orderShipType } from '@/api/order'
  import { addThousandSeparator } from 'kits'
  const mergeColumn = ['效果图','SKU ID','中文报关名/英文报关名','中文品名/英文品名','规格','产品材质','总数量','剩余待出运数量','操作']
  export default {
    name: 'splitTable',
    components: {
      popoverShip,
      splitProductsShipment
    },
    data() {
      return {
        tabledata: [],
        selectAll: [],
        parentProductSelect: [],
        childProductSelect: [],
        dialogVisible: false,// 操作弹窗
        editProductData:{},// 修改产品列表
        productSubListTemplate:{
          caseNum:'',
          customerDeliveryDate:'',
          firmFlag:0,
          grossWeight:'',
          orderProductUniqueId:'',
          overLoadingQuantity:'',
          planShippingDate:'',
          planShippingQuantity:'',
          shippingMethod:'',
          shippingMethodId:'',
          shippingPlanOrderId:'',
          shippingPlanOrderProductId:'',
          productSubId:'',
          sku:'',
          volume:'',
          shippedFlag:0,
        },// 子产品列表模板
        shippingMethodList:[],//运输方式列表
        numRelax:1,//溢箱数比例
        expandedRowKeys:[],
      }
    },
    props: {
      tableList: {
        default: () => [],
        type: Array,
      },
      modifyParams:{
        type:Object,
        required:true
      }
    },
    filters: {
      emptyFilter(val) {
        if (!val && val !== 0) {
          return '--'
        } else {
          return val
        }
      },
    },
    created() {
      this.getDict();
      this.tabledata = [...this.tableList]
      this.tabledata = this.tabledata.map((item) => {
        // checkFlag  2：只能勾选子产品 1：只能勾选主产品；0：随便勾"
        if (item.checkFlag == 1) {
          item.childProduct = item.childProduct?.map((ele) => {
            return {
              ...ele,
              disabled: true,
            }
          })
        }

        return {
          ...item,
          checked: false,
          disabled: item.checkFlag == 2 ? true : false,
        }
      })
    },
    methods: {
      // 获取rowkey
      getRowKey(row) {
        if (row.mainFlag == 1 && row.splitFlag == 1) {
          return row.id;
        } else {
          return row.id + '_' + Math.random().toString(36).slice(2, 11);
        } 
      },
      // 获取运输方式
      getDict(){
        orderShipType().then((res) => {
          this.shippingMethodList = res.data
        })
        // 获取溢装数比例
        OrderInteractor.orderGetDict({ amountType: 4 }).then((res) => {
          if (res?.code === '000000' && res?.data?.length) {
            this.numRelax = res.data[0]?.amountShort ?? 1
          }
        })
      },
      // 合并单元格 
      arraySpanMethod({ row, column, rowIndex, columnIndex },arr) {
        const rowsValue = this.findRowspanGroups(arr);
        // 需要合并的列数lable
        for (let i = 0; i < rowsValue.length; i++) {
          const [start, count] = rowsValue[i];
          if (rowIndex >= start && rowIndex < start + count) {
            if (column.type === 'expand' || mergeColumn.includes(column.label)) {
              return {
                rowspan: rowIndex === start ? count : 0,
                colspan: 1
              };
            }
          }
        }
      },
      // 获取相同id的起始坐标和数量
      findRowspanGroups(arr) {
        let result = [];
        let startIndex = 0;
        // 遍历数组
        for (let i = 1; i <= arr.length; i++) {
          if (i === arr.length || arr[i].id !== arr[i - 1].id) {
            result.push([startIndex, i - startIndex]);
            startIndex = i;
          }
        }
        return result;
      },
      // 操作弹窗
      openDialog(row,arr) {
        const objData = {
          sku:row.sku,
          productCn:row.productCn,
          productEn:row.productEn,
          productSpecs:row.productSpecs,
          totalNum:row.totalNum,
          orderProductUniqueId:row.orderProductUniqueId,
          firmFlag:row.firmFlag,
          customerDeliveryDate:row.customerDeliveryDate,
          shippingPlanOrderProductId:row.shippingPlanOrderProductId,
          reason:''
        }
        const allSubProduct =  arr.filter(item => item.id === row.id)
        const editTableData = allSubProduct.reduce((previousValue, currentValue) => {
          let objValue = {}
          for (let key in this.productSubListTemplate) {
            objValue[key] = currentValue?.[key] ?? ''
          }
          previousValue.push(objValue)
          return previousValue
        },[])
        this.editProductData = {
          ...objData,
          ...this.modifyParams,
          productSubList: editTableData,
          delProductSubIds:[]
        }
        this.dialogVisible = true
      },
      // 弹窗点击确定
     async confirmFn(){
        const bol = await this.$refs.splitProductsShipmentRef.checkTableForm()
        if(!bol)return;
        const { productSubList } = this.editProductData
        const overLoadingQuantityNum = productSubList.reduce((previousValue, currentValue) => {
          return previousValue + Number(currentValue.overLoadingQuantity)
        }, 0)
        if(overLoadingQuantityNum > this.numRelax * this.editProductData.totalNum){
          this.$confirm(`溢装数超过了溢装上限，请确认?`, '提示', {
            confirmButtonText: '确认',
            cancelButtonText: '取消',
            type: 'warning',
          })
            .then(() => {
              this.modifyProduct()
            })
            .catch(() => {})
        }else{
          this.modifyProduct()
        }

      },
      // 修改操作
     async modifyProduct() {
      const res = await ShippingPlanInteractor.modifyShippingPlanOrderProductApi(this.editProductData)
        if(res?.code === '000000'){
          this.$message.success('修改成功')
          this.dialogVisible = false
          this.$emit('refreshTable')
        }
      },
      // 显示详情
      showMarkDetail(val) {
        this.$emit('showMarkDetail', val)
      },
      // 显示产品详情
      showProDetail(val) {
        if (val.sku.indexOf('FL') > -1) return
        this.$emit('showProDetail', val.productId)
      },
      /*
      要求：选中父产品，子产品也全部被选中
            子产品被全选，父产品不会被选中
            选中父产品，子产品也全部被选中，取消一个子产品时，父产品不被选中
    */
    tableSelect(val) {
        this.resetChecked()
        // 选中父产品，子产品也全部被选中
        if (val.length > 0) {
          this.selectAll = [...val]
          val?.forEach((item) => {
            if(!item.shippedFlag) this.$set(item, 'checked', true)
            item?.childProduct?.forEach((ele) => {
              if(!ele.shippedFlag) this.$set(ele, 'checked', true)
            })
          })
        }
      },
      // 父产品全部选中，子产品也全部选中
      changeParentSelect(val) {
        this.$refs.table.toggleRowExpansion(this.tabledata, true)
        val?.childProduct?.forEach((item) => {
          this.$set(item, 'checked', val.checked)
        })
      },
      changeChildStatus(val) {
        let state = val.childProduct.every((item) => {
          return item.checked
        })
        if (!state) this.$set(val, 'checked', false)
      },
      //重置所有勾选
      resetChecked() {
        this.tabledata.forEach((item) => {
          this.$set(item, 'checked', false)
          item?.childProduct?.forEach((ele) => {
            this.$set(ele, 'checked', false)
          })
        })
      },
      changeTable(row, expandedRows) {
        if (row.mainFlag == 1 && row.splitFlag == 1) {
          if (expandedRows.includes(row)) {
            // 如果该行展开，保存其 ID
            this.expandedRowKeys.push(row.id);
          } else {
            // 如果该行收起，移除其 ID
            const index = this.expandedRowKeys.indexOf(row.id);
            if (index > -1) {
              this.expandedRowKeys.splice(index, 1);
            }
          }
          localStorage.setItem('expandedRows', JSON.stringify(this.expandedRowKeys));
        } else {
          this.$refs.table.toggleRowExpansion(row, false)
        }
      },
       // 处理千分位
      addThousandSeparator(val) {    
        return val ? addThousandSeparator(val,true,0) :'--'   
      },
    },
    mounted() {
      // 页面加载时从 localStorage 获取展开行的状态
      const expandedRows = JSON.parse(localStorage.getItem('expandedRows')) || [];
      // 通过 key 展行上次展开行
      this.expandedRowKeys = expandedRows;
    }
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .el-table__expanded-cell {
      padding: 0 !important;
    }
    .el-table__body tr {
      min-height: 0px !important;
    }
    .shipped-flag{
      .el-checkbox{
        &.is-disabled{
          .el-checkbox__inner{
            background-color: #f2f2f2 !important;
            border-color: #f2f2f2 !important;
          }
        }
      }
    } 

  }
  .jz {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-tag-other {
    position: absolute;
    right: 4px;
    z-index: 2;
    background-color: #fff7e8;
    border-color: #ffefd1;
    color: #ffae18;
  }
  ::v-deep .query-dialog{
    .el-dialog__header{
      border-bottom: 1px solid #C0C4CC;
    }
    .el-dialog__body{
      min-height: 400px;
      max-height: 600px;
      overflow-y: auto;
    }
    .el-dialog__footer{
      padding: 20px;
      border-top: 1px solid #C0C4CC;
    }
  }
  .text-d7{
    color: #999 !important;
  }
</style>
