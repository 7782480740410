<!--
  功能：出运计划详情
  作者：jiangyongqiang
  邮箱：missfoxw@163.com
  时间：2021年12月16日 19:43:04
  版本：v1.0
  修改记录：
  修改内容：
  修改人员：
  修改时间：
-->
<template>
  <div class="custom-wrap">
    <div class="custom-top">
      <div class="flex">
        <el-page-header :content="planInfo.planNumber" @back="goBack()" v-if="!$route.query.noReturn"></el-page-header>
      </div>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane label="出运计划信息" name="info">
        <div class="base-info">
          <h4>基本信息</h4>
          <div class="custom-detail">
            <el-row>
              <el-col>
                <em style="width: 85px">出运计划单：</em>
                <span style="width: calc(100% - 85px)">
                  {{ planInfo.planNumber | emptyFilter }}
                </span>
              </el-col>
              <el-col>
                <em>订单编号：</em>
                <span v-if="planInfo.piNumber" class="el-link el-link--primary" @click="goDetail(planInfo.piNumber)">
                  {{ planInfo.piNumber }}
                </span>
                <span v-else>--</span>
              </el-col>
              <!-- <el-col>
                <em>运输方式：</em>
                <span>{{ planInfo.shippingMethod | emptyFilter }}</span>
              </el-col> -->
              <el-col>
                <em>客户代表：</em>
                <span>{{ planInfo.businessName | emptyFilter }}</span>
              </el-col>
              <el-col>
                <em>客户名称：</em>
                <span>{{ planInfo.customerName | emptyFilter }}</span>
              </el-col>
              <!-- <el-col>
                <em>装运日期：</em>
                <span>
                  {{
                  planInfo.shippingDate
                  ? planInfo.shippingDate.split(' ')[0]
                  : '-'
                  }}
                </span>
              </el-col> -->
              <!-- <el-col>
                <em>最晚交期：</em>
                <span>
                  {{
                  planInfo.deliveryDate
                  ? planInfo.deliveryDate.split(' ')[0]
                  : '-'
                  }}
                </span>
              </el-col> -->
            </el-row>
          </div>
          <h4>收货信息<span style="font-size: 12px;color:#515a6e;font-weight: 400;">（若要修改收货地址，请在销售订单里修改）</span></h4>
          <div class="c-table-wrap">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>国家</th>
                  <th>收货地址</th>
                  <th>收货人</th>
                  <th>收货公司</th>
                  <th>联系电话</th>
                </tr>
              </thead>
              <tbody>
                <!-- v-for="(item, i) in planInfo.goodsInfo" :key="i" -->
                <tr>
                  <td>{{ planInfo.country | emptyFilter }}</td>
                  <td>{{ planInfo.destination | emptyFilter }}</td>
                  <td>{{ planInfo.receivingPerson | emptyFilter }}</td>
                  <td>{{ planInfo.receivingCompany | emptyFilter }}</td>
                  <td>{{ planInfo.receivingPhone | emptyFilter }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div style="margin: 20px 0 10px 0">
            <span class="title">出运产品</span>
            <el-button @click="formalShip">正式出运</el-button>
            <span style="font-size: 12px;margin-left: 4px;">（若同一行产品需要拆分出运，请点击产品行后面的“修改”拆分出运计划）</span>
            <!-- <span class="ml-10" style="color: #999999">
              Tips：若产品是组装好一起发货，请勾选主产品安排出运；若子产品是分箱或分批出运，请勾选子产品安排出运；
            </span> -->
          </div>
          <div class="c-table-wrap" v-if="showSplitTable">
            <split-table :tableList="planInfo.productDetails" @showProDetail="showProDetail" :modifyParams = "modifyParams"
              @showMarkDetail="showMarkDetail" :key="planInfo.id" ref="detailTable" @refreshTable="getDetail"></split-table>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="操作日志" name="operationLog">
        <OperateLogs ref="operateLogs"  :showModule="false" :bizId="bizId"  v-if="activeName == 'operationLog'" />
      </el-tab-pane>
    </el-tabs>
    <!-- 唛头弹窗 -->
    <el-dialog :visible="showMarkDialog" title="唛头" @close="closeMarkDialog" width="450px">
      <div class="custom-detail">
        <el-row>
          <el-col :span="24" style="width: 100%">
            <em style="vertical-align: top">主唛：</em>
            <p v-for="(item, index) in getMainLabel(currentRow.mainLabel)" :key="index">
              {{ item }}
            </p>
          </el-col>
        </el-row>

        <el-row>
          <el-col :span="24" style="width: 100%">
            <em style="vertical-align: top">侧唛：</em>
            <span v-if="currentRow.sideMark" v-html="currentRow.sideMark"></span>
            <span v-else>--</span>
          </el-col>
        </el-row>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import { ShippingPlanInteractor } from '@/core/interactors/shipment/shipment-plan'
  import { OrderSettingInteractor } from '@/core'
  import navigateTo, { PAGE_TYPES } from '@/utils/navigate-util'
  import splitTable from './components/splitTable.vue'
  import OperateLogs from '@/components/operate-logs'
  export default {
    name: 'demo',
    props: {},
    components: {
      splitTable,
      OperateLogs
    },
    // 组件状态值
    data() {
      return {
        activeName:'info',//当前激活的tab
        showSplitTable:false,
        planInfo: {
          products: [],
          goodsInfo: [],
        },
        showMarkDialog: false,
        currentRow: {},
        modifyParams:{
          shippingPlanOrderId:'',
          orderVersion:'',
          piNumber:''
        },//修改参数
      }
    },
    computed: {
      bizId() {
        return this.$route.query.shippingPlanOrderId
      }
    },
    watch: {},
    methods: {
      goDetail(orderNumber) {
        return navigateTo(this, {
          orderNumber,
          pageType: PAGE_TYPES.salesOrderDetail,
        })
      },
      getMainLabel(mark) {
        if (!mark) return []
        return mark.split('\n')
      },
      // 唛头弹窗
      showMarkDetail(item) {
        this.currentRow = item
        this.showMarkDialog = true
      },
      // 关闭唛头弹窗
      closeMarkDialog() {
        this.showMarkDialog = false
      },
      // 返回
      goBack() {
        this.$router.go(-1)
      },
      // 获取详情
      getDetail() {
        this.showSplitTable = false
        const shippingPlanOrderId = this.$route.query.shippingPlanOrderId
        ShippingPlanInteractor.getShipmentPlanDetail({
          shippingPlanOrderId: shippingPlanOrderId,
          shippingPlanOrderProductIds: '',
        }).then((res) => {
          // 处理拆分产品数据
          if (res?.code === '000000') {
            if ( res?.data?.productDetails?.length)
             {
              // 有子产品的先把子产品铺开
              res.data.productDetails = res.data.productDetails.map((item) => {
                if(item?.childProduct?.length){
                  item.childProduct = this.handleSplitProductData(item.childProduct)
                }
                return item
              }) 
              // 把拆分产品平铺
             res.data.productDetails = this.handleSplitProductData(res.data.productDetails)
            }else{
              res.data.productDetails = []
            }
            this.planInfo = res.data || {}
            for(let k in this.modifyParams){
              this.modifyParams[k] = this.planInfo?.[k] ?? ''
            }
          }
          this.showSplitTable = true
        }).catch((err) => {
          console.log(err)
          this.showSplitTable = true
        })
      },
      // 处理拆分产品数据
      handleSplitProductData(arr) {
        return arr.reduce((previousValue, currentValue) => {
          const subArr = currentValue?.productSubList ?? [];
          for(let k of subArr){
            let obj = { ...currentValue, ...k }
            if(!obj.sku)obj.sku = currentValue.sku
            previousValue.push(obj)
          }
          return previousValue
        },[])
      },
      //产品详情
      showProDetail(proId) {
        let routeData = this.$router.resolve({
          path: '/product/ProductView',
          query: {
            proId,
            editProduct: 1,
            editProductBelonger: '',
            noReturn: true,
          },
        })
        window.open(routeData.href, '_blank')
      },
      formalShip() {
        /*
      1、在整个data中判断有无勾选产品
      2、如果勾选的是子产品。将父产品也同步带过去，将未勾选的父产品塞到子产品中parentList
      3、如果勾选父产品。将子产品也同步带过去
      4、没有子产品，直接待父的过去
      */

        // 1、判断父产品是否勾选
        let newArr = [] // 存放改造后的数据
        let arr = this.$refs.detailTable.tabledata
        // 主产品不能出运
        arr.forEach((item) => {
          if (item.mainFlag == 1 && item.splitFlag == 1) {
            this.$set(item, 'checked', false)
          }
        })

        //2、判断子产品是否勾选
        arr.forEach((item) => {
          if (item.checked) {
            newArr.push(item)
          } else {
            item.childProduct?.forEach((ele) => {
              if (ele.checked) {
                this.$set(ele, 'parentList', JSON.stringify([item]))
                // ele.parentList.push(item)
                newArr.push(ele)
              }
            })
          }
        })
        if (newArr.length == 0) {
          this.$message.warning('请选择出运产品')
          return
        }
        let outArr = []
        arr.forEach((item) => {
          if (item.checked) {
            outArr.push(item)
          } else {
            if (item.childProduct?.length > 0) {
              item.childProduct.forEach((ele) => {
                if (ele.checked) {
                  outArr.push(ele)
                }
              })
            }
          }
        })

        outArr = outArr.map((item) => {
          item.arrangeChildProducts = item.childProduct
          return item
        })

       const bol = this.checkRepeatSubProduct(outArr)
        if(bol){
          this.$message.warning('不能选择同一个产品、不同的产品行')
          return
        }
       
        let shippingPlanOrderProductIds = []
        let shippingPlanOrderProductSubIds = []
        newArr.forEach((item) => {
          if (item.checked) {
            shippingPlanOrderProductIds.push(item.shippingPlanOrderProductId)
            shippingPlanOrderProductSubIds.push(item.productSubId)
          }
          item.childProduct?.forEach((ele) => {
            shippingPlanOrderProductIds.push(ele.shippingPlanOrderProductId)
            shippingPlanOrderProductSubIds.push(ele.productSubId)
          })
        })
        ShippingPlanInteractor.arrangeShipQuery({
          shippingPlanOrderId: this.$route.query.shippingPlanOrderId,
          arrangeProductList: outArr,
        }).then((res) => {
          if (res && res.code === '000000') {
            if (
              res.data.productArranges &&
              res.data.productArranges.length > 0
            ) {
              const push = () => {
                this.$router.push({
                  path: '/order/new-shipment-sheet',
                  query: {
                    shippingPlanOrderId: this.$route.query.shippingPlanOrderId,
                    shippingPlanOrderProductIds:
                      shippingPlanOrderProductIds.toString(),
                      shippingPlanOrderProductSubIds:shippingPlanOrderProductSubIds.toString()  
                  },
                })
              }
              this.checkShowTip(push)
            } else {
              this.$confirm('产品无库存，无法安排出运。', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
              })
                .then(() => { })
                .catch(() => { })
            }
          }
        })
      },
       // 判断是不是有重复的拆分产品
      checkRepeatSubProduct(arr){
        const seen = new Set();
        for(let k of arr){
          if(seen.has(k.id)){
            return true
          }
          seen.add(k.id)
        }
        return false
      },
      // 在跳转前判断要不要显示弹框提示，0:不提醒，1：提醒
      async checkShowTip(push) {
        try {
          const { orderCode } = this.$route.query
          const { code, data } = await OrderSettingInteractor.getOrderShippingReminderFlagApi(orderCode)
          if (code === '000000') {
            if (data == 1) {
              this.$confirm('请尽快安排催收，以免影响出运或供应商款项支付。', '该订单装运前款项未收齐', {
                confirmButtonText: '安排出运',
                cancelButtonText: '取消出运',
                type: 'warning',
                customClass: 'arrange-shipment-tip',
              }).then(() => {
                push()
              })
            } else {
              push()
            }
          } else {
            push()
          }
        } catch (e) {
          push()
          console.log(e)
        }
      },
    },

    filters: {
      emptyFilter(val) {
        if (!val && val !== 0) {
          return '--'
        } else {
          return val
        }
      },
    },
    created() {
      // 进入页面清除记录的展开行
      localStorage.removeItem('expandedRows')
      // 获取详情
      this.getDetail()
    },
  }
</script>

<style scoped lang="scss">
  .custom-wrap {
    .base-info {
      height: calc(100% - 44px);
      overflow-y: auto;
    }

    .tag-wrap {
      position: relative;
      display: flex;
      justify-content: center;

      .el-tag {
        position: absolute;
        top: -10px;
        right: 0;
        z-index: 2;
      }

      .el-image {
        width: 50px;
        height: 50px;
        border-radius: 2px;
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 14px;
    font-family: 'PingFang-Bold';
    margin-right: 10px;
  }
</style>
